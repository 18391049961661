#root {
	min-height: 100vh;
	display: grid;
	grid-template-rows: auto 1fr auto;
}

body {
	/* background-color: #ebebeb; */
	background-image: linear-gradient(to left, rgba(3, 29, 2, 0.904), #2c3a3ad0);
	color: #fff;
}

:root {
	/* --btn-primary-color: #1c6965; */
	/* --btn-primary-color: #0c6bb9; */
	--btn-primary-color: #05929c;
	--btn-secondary-color: #d9e2ef;
	--btn-danger-color: #c01600;
}

.home-page {
	position: relative;
	height: 78vh;
	background: url('./images/home.jpg') no-repeat center center/cover;
}

.home-page .text {
	margin-top: 7rem !important;
}

.home-page .text h1:nth-child(1) {
	font-size: 3rem !important;
}

.home-page .text h1:nth-child(2) {
	font-size: 4rem !important;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	box-shadow: inset 80px 80px 250px #000000, inset -80px -80px 250px #000000;
}

.messages .modal-dialog {
	max-width: 90% !important;
}

th,
tr {
	text-align: center !important;
}

.card,
.accordion,
.modal {
	color: #000 !important;
}

.float-right {
	right: -25px !important;
}

.read-more:hover {
	text-decoration: underline;
	cursor: pointer;
}

.single-discussion {
	border-radius: 2px;
	color: #000;
}

.single-discussion:hover {
	background-color: var(--btn-primary-color) !important;
	color: #fff;
	cursor: pointer;
}

.dropdown-toggle::after {
	display: none !important;
}

.social-links {
	color: #fff !important;
	margin: 0 5px !important;
}

p {
	margin: 0 !important;
}

.small-fs {
	font-size: 13px !important;
}

.link {
	color: #000;
	text-decoration: none;
}

.link:hover {
	color: var(--btn-primary-color);
	text-decoration: underline;
}

.link.recruteur {
	color: var(--btn-primary-color);
}

.dropdown-item:active {
	background-color: var(--btn-primary-color);
}

.page-item .page-link {
	color: var(--btn-primary-color);
}

.page-item.active .page-link {
	background-color: var(--btn-primary-color);
	border-color: var(--btn-primary-color);
	color: #fff;
}

.progress-bar {
	background-color: var(--btn-primary-color);
}

.icons {
	margin: 0 5px !important;
}

/* Image */
.dropdown-image {
	width: 37.5px;
	height: 37.5px;
	border-radius: 50%;
}

.candidat-image {
	width: 90px;
	height: 90px;
	border-radius: 50%;
	border: 2px solid var(--btn-primary-color);
}

/* Buttons */
.bg-primary {
	background-color: var(--btn-primary-color) !important;
}

.text-primary {
	color: var(--btn-primary-color) !important;
}

.btn-primary {
	background-color: var(--btn-primary-color) !important;
	border: var(--btn-primary-color) !important;
}

.btn-secondary {
	background-color: var(--btn-secondary-color) !important;
	border: var(--btn-secondary-color) !important;
	color: #000;
}

.btn-secondary:hover,
.btn-secondary:focus {
	background-color: var(--btn-secondary-color) !important;
	color: #000;
}

.btn:focus,
input:focus,
textarea:focus,
select:focus,
.accordion-button:focus,
.page-item .page-link:focus {
	box-shadow: none !important;
}

.btn:hover {
	opacity: 0.9;
}

.btn-danger {
	background-color: var(--btn-danger-color) !important;
}

.btn-del {
	background-color: var(--btn-danger-color) !important;
	padding: 0 7px 2px 7px !important;
}

.btn.facebook {
	background-color: #3b5998 !important;
}

.btn.twitter {
	background-color: #00acee !important;
}

.btn.instagram {
	background: linear-gradient(
		45deg,
		#f09433 0%,
		#e6683c 25%,
		#dc2743 50%,
		#cc2366 75%,
		#bc1888 100%
	);
}

.btn.linkedin {
	background-color: #0072b1 !important;
}

.btn.youtube {
	background-color: #c4302b !important;
}

.btn.github {
	background-color: #171515 !important;
}

.save {
	color: var(--btn-primary-color);
}

.admin-cards:hover,
.admin-cards:hover .save {
	cursor: pointer;
	background-color: var(--btn-primary-color);
	color: #fff !important;
}

/* Search */
/* .search input {
	border: 0 !important;
	border-radius: 1px !important;
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	height: 40px !important;
}

.search .icon {
	background-color: #fff !important;
	height: 40px !important;
	width: 25px !important;
	display: grid !important;
	place-items: center !important;
	cursor: pointer;
}

.search .icon:hover {
	opacity: 0.9;
}

.list-group {
	max-height: 100px;
	margin-bottom: 10px;
	overflow: hidden;
	overflow-y: auto;
}

.list-group-item:hover {
	background-color: var(--btn-primary-color);
	color: #fff;
	cursor: pointer;
}

.list-group::-webkit-scrollbar {
	width: 10px;
}
.list-group::-webkit-scrollbar-track {
	background-color: #fff;
}
.list-group::-webkit-scrollbar-thumb {
	background-color: var(--btn-secondary-color);
	border-radius: 20px;
} */

/* Register */
.register div {
	width: 50%;
}

.register div a {
	color: #aaaaaa;
	text-decoration: none;
}

.register div.active {
	/* border-bottom: 3px solid var(--btn-primary-color); */
	border-bottom: 3px solid #fff;
}

.register div.active a {
	/* color: var(--btn-primary-color); */
	color: #fff;
}

.register div a:hover {
	/* color: var(--btn-primary-color); */
	color: #fff;
}

/* Profile */
.profile > div:first-child {
	color: #fff;
}

.profile h1 {
	font-size: 3rem !important;
}

/* Profile photo */
.profile-photo div {
	top: 30px !important;
}

.profile-photo div.photo2 {
	top: 35px !important;
	right: 110px !important;
}

.profile-photo div.photo2 .icon {
	color: #fff;
	background-color: var(--btn-primary-color) !important;
	font-size: large;
	padding-bottom: 10px;
}

.photo {
	width: 250px;
	height: 250px;
	border-radius: 50%;
	border: 2px solid var(--btn-primary-color);
}

/* Diplome, stage & experience */
.dse {
	background-color: #f0f0f0;
	border-radius: 5px;
}

/* Competence */
.competence {
	background-color: #f0f0f0;
}

/* Media queries */
@media (max-width: 992px) {
	.width-100 {
		width: 100% !important;
	}
}

@media (max-width: 768px) {
	footer .fs-4 {
		margin: 0.5rem 0 !important;
	}

	.profile h1 {
		font-size: 2.3rem !important;
	}

	.discussion-page,
	.discussion {
		height: 50vh;
	}

	.home-page .text h1:nth-child(1) {
		font-size: 2rem !important;
	}

	.home-page .text h1:nth-child(2) {
		font-size: 3rem !important;
	}

	.overlay {
		box-shadow: inset 50px 50px 250px #000000, inset -50px -50px 250px #000000;
	}
}
